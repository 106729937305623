import styled from 'styled-components'
import { Link } from 'gatsby'

export const ListLink = styled(Link)({
  color: 'black'
})

export const FilterField = styled.input({
  marginBottom: '10px',
  padding: '5px 10px',

  ':focus': {
    outline: 'none',
    filter: 'drop-shadow(0 0 2px rgb(14, 98, 201))'
  }
})
