import React from 'react'
import PropTypes from 'prop-types'
import kebabCase from 'lodash/kebabCase'
import { graphql } from 'gatsby'
import Base from '../components/Base'
import Meta from '../components/Meta'
import { ListLink } from '../templates/styles'

const CategoriesPage = props => {
  const { group } = props.data.allMdx

  return (
    <Base pathName={props.location.pathname}>
      <Meta location={props.location} pageTitle="Categories page" />
      <h2>Categories</h2>

      <ul>
        {group.map(category => (
          <li key={category.fieldValue}>
            <ListLink
              to={`/category/${kebabCase(category.fieldValue?.toLowerCase())}`}
            >
              {category.fieldValue} ({category.totalCount})
            </ListLink>
          </li>
        ))}
      </ul>
    </Base>
  )
}

CategoriesPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired
        }).isRequired
      )
    })
  })
}

export default CategoriesPage

export const pageQuery = graphql`
  query {
    allMdx(limit: 2000) {
      group(field: frontmatter___categories) {
        fieldValue
        totalCount
      }
    }
  }
`
